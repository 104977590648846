<template>
  <div>
    <!--
    <section>
      <v-row no-gutters>
        <v-col class="videoContainer">

        </v-col>
      </v-row>
    </section>-->
    <section class="sectionMargin">
      <v-col cols="12"><img class="max100" src="../../public/Resources/images/congres/Logo_CIEF.png" title="logo Congrès international de l'entrepreneuriat féminin" alt="visuel de l'entreprise Congrès international de l'entrepreneuriat féminin" /></v-col>

      <v-row no-gutters>
        <v-col class="d-flex justify-center card">
          <v-row class="whyContainer">
            <div class="portrait"><img title="Innover de l'entreprise Congrès international de l'entrepreneuriat féminin" alt="photo d'arriere plan du bouton mission"  src="/Resources/images/congres/innover.jpg" /></div>
            <button class="whyButton"><h2>Innover</h2></button>
          </v-row>
        </v-col>
        <v-col class="d-flex justify-center card">
          <v-row class="whyContainer">
            <div class="portrait"><img title="Réseauter de l'entreprise Congrès international de l'entrepreneuriat féminin"  alt="photo d'arriere plan du bouton mission"  src="/Resources/images/congres/reseauter.jpg" /></div>
            <button class="whyButton"><h2>Réseauter</h2></button>
          </v-row>
        </v-col>
        <v-col class="d-flex justify-center card">
          <v-row class="whyContainer">
            <div class="portrait"><img title="Diversifier de l'entreprise Congrès international de l'entrepreneuriat féminin" alt="photo d'arriere plan du bouton mission"  src="/Resources/images/congres/diversifier.jpg" /></div>
            <button class="whyButton"><h2>Diversifier</h2></button>
          </v-row>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row id="congressInternational" no-gutters>
        <v-col cols="12" class="" style="position: relative; z-index: 100;">
          <v-col class="congressInternationBG" style="">
            <v-row><v-col><h3 class="txt-c" style="font-family: serif;">Une nouvelle ère pour l'entrepreneuriat féminin</h3></v-col></v-row>
            <v-row><v-col><p class="txt-c">Le <span class="f-primary">Congrès international de l'entrepreneuriat féminin</span> ouvre ses portes pour la prévente des billets!</p></v-col></v-row>
            <v-row><v-col><p class="txt-c">Un évènement à ne pas manquer</p></v-col></v-row>
            <v-row><v-col><p class="txt-c" style="font-weight: 700; font-family: roboto; color: rgb(var(--primary));">7h30 le 24 octobre 2024 à montréal</p></v-col></v-row>
            <v-row><v-col>
              <p class="txt-c" style=" font-family: roboto; ">
                <span style="font-weight: 700; color: rgb(var(--primary));">À l'hôtel Plaza Le Rizz</span><br>
                6630 Rue Jarry E<br>
                Saint-Léonard, QC HIP 1W3<br>
              </p>
              <p class="txt-c" style=" font-family: roboto; font-size: 1em;">(Stationnement inclus)</p>
            </v-col></v-row>
            <v-row><v-col><p class="txt-c" style=" font-family: serif; text-transform: uppercase;">Profitez de la prévente en vous inscrivant dès maintenant!</p></v-col></v-row>
          </v-col>
        </v-col>
      </v-row>
    </section>
    <section id="services" style="position: relative; margin-top: -5%; z-index: 10;" >
      <v-parallax height="400" src="/Resources/images/congres/parralax.jpg" title="arriere plan du Congrès international de l'entrepreneuriat féminin" alt=" Photos d'arriere plan du Congrès international de l'entrepreneuriat féminin"  style=" background-size: cover;">
        <a href="/Resources/documents/ProgrammationCIEF-2024.pdf" target="_Blank"> <button style="position: absolute; top:50%; left:50%; transform: translate(-50%,-50%);"><h2>Programmation</h2></button> </a>
      </v-parallax>
    </section>
    <section id="infoCongres" >
      <v-row style="padding: 0px 5%; ">
        <v-col cols="12" >
          <p>Le <span class="f-primary">Congrès international de l'entrepreneuriat féminin (C.I.E.F.)</span> est le rendez-vous incontournable pour les femmes entrepreneures et les professionnels engagés, désireux de s'immerger et de s'impliquer activement dans le monde dynamique des affaires.</p>
          <p>Rejoignez-nous au <span class="bold italic">Plaza Le Rizz,</span> dans une ambiance haut de gamme, pour une journée enrichissante, ponctuée d'<span class="bold">inspiration,</span> de <span class="bold">réflexion</span> et de <span class="bold">réseautage.</span></p>
          <p>Cette plateforme unique rassemble des femmes leaders ainsi que des organismes dédiés à l'avancement de l'entrepreneuriat féminin, offrant des conférences captivantes et des ateliers interactifs conçus pour amener une ère de changement dans l'écosystème entrepreneurial.</p>
          <p>Le C.I.E.F. favorise une <span class="bold">mixité enrichissante,</span> invitant non seulement des femmes mais aussi des hommes qui <span class="bold">soutiennent l'entrepreneuriat féminin,</span> afin de collaborer et de réfléchir ensemble aux défis et opportunités de ce secteur. </p>
          <p class="bold">De plus, participer au congrès vous permettra de :</p>
          <ul style="text-align: left; padding: 10px 5%;">
            <li>développer vos affaires non seulement à l'échelle nationale mais aussi sur le marché international;</li>
            <li>accéder directement à des informations et des compétences innovantes dans un environnement entrepreneurial en constante évolution;</li>
            <li>augmenter significativement votre visibilité auprès d'un public diversifié et influent, élargissant ainsi votre réseau professionnel et ouvrant la porte à de nouvelles opportunités de collaboration.</li>
          </ul>
          <p>Ne manquez pas cette occasion exceptionnelle de connecter avec d'autres professionnel.les ambitieu.ses et de faire avancer votre entreprise.</p>
          <p style="font-family: argentReg; color: rgb(var(--fifth));">Profitez du tarif de la prévente en réservant votre place dès maintenant, et participez à une journée qui pourrait transformer votre parcours entrepreneurial.</p>          
        </v-col>
        <a class="ShopButton" href="https://www.eventbrite.ca/e/congres-international-de-lentrepreneuriat-feminin-tickets-916424629737#:~:text=Le%20Congr%C3%A8s%20international%20de%20l,r%C3%A9fl%C3%A9chir%20tout%20l'%C3%A9cosyst%C3%A8me%20entrepreneurial." target="_Blank">
          <button>
            <h2>Accéder à la billetterie</h2>
            <p style="color: white; line-height: 10px; font-size: 14px; text-align: center;">Prévente avec tarif préférentiel</p>
          </button>
        </a>
        <v-col class="mt-10" id="Programmation" cols="12" style="border: 5px solid rgb(var(--primary)); background-color: rgba(var(--primary),0.1);">
          <v-row style="background-color: rgb(var(--primary)); padding: 10px;"><h2 style="font-family: argentReg !important; color: white; font-size: 2em;" class="">Un programme unique</h2></v-row>
          <v-row >
            <ul style="text-align: left; padding: 40px 5%;">
              <li>Des conférencier.es de renom et inspirant.es;</li>
              <li>Un panel mixte et inclusif, incluant le Défi Innovation;</li>
              <li>Un atelier mystère et novateur de résolution de problèmes;</li>
              <li>Un cocktail dinatoire et réseautage pour les participant.es VIP;</li>
              <li>Et d'autres surprises!</li>
            </ul>
          </v-row>
          <v-row><v-col><p>Il sera important de mentionner toute allergie lors de la réservation du billet.</p></v-col></v-row>
        </v-col>
      </v-row>
    </section>
    <section id="PricingCongres" style="padding: 0px 5%;">
      <v-row style=" border: 5px solid rgb(var(--primary));">
        <v-col cols="12" md="6" style="">
          <h2 class="SectionTitle">Tarifs</h2>
          <v-row  class="mt-10" style="padding: 0px 5%;">
            <v-col  class="borderRight textMobile"  style="text-align: left;">
              <ul>
                <li><p class="mt-5">Accès aux conférences & ateliers</p></li>
                <li><p>Déjeuner & pauses</p></li>
                <li><p>Mastermind virtuel <span style="font-size: clamp(8px,1vw,10px); font-weight: 100;">(avant le congrès)</span></p></li>
                <li><p>Cocktail - réseautage</p></li>
                <p style="font-size: clamp(10px,1vw,12px) !important; font-weight: 100;">inclut des rencontres avec  certain-es conférencier-es & autres personnalités d’affaires</p>               
              </ul>
            </v-col>
            <v-col class="widthDot">
              <v-row>
                <v-col class="borderRight vipWidth">
                  <p class="ticketTitle">Vip</p>
                  <p class="marginTopMobile safari_only"><span class=" dot"></span></p>
                  <p class="safari_only_dot"><span class=" dot"></span></p>
                  <p class="safari_only_dot"><span class=" dot"></span></p>
                  <p class="safari_only_dot"><span class=" dot"></span></p>
                  <p class="safari_only_dot"><span class="includeTitle ">-</span></p>
                  <p class="safari_only_dot"><span class="includeTitle ">-</span></p>
                </v-col>
                <v-col class="normalWidth">
                  <p class="ticketTitle">Régulier</p>
                  <p class="marginTopMobile"><span class="dot"></span></p>
                  <p class=""><span class="dot"></span></p>
                  <p class="noneIncludeTitle">-</p>
                  <p class="noneIncludeTitle">-</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="ticketChoice" style="padding: 0px 5%;">
                <v-col >
                  <p class="ticketTimeTitle">PRÉVENTE - <span class="ticketTimeTitleName">Lève-tôt (jusqu’au 14 août)</span></p>
                  <p class="ticketTimeTitle">RÉGULIER - <span class="ticketTimeTitleName">(jusqu’au 15 septembre)</span></p>
                  <p class="ticketTimeTitle">COUCHE-TARD</p>
                </v-col>
                <v-col class="widthPrice">
                  <p class="priceTicket">327$ + tx</p>
                  <p class="priceTicket">397$ + tx</p>
                  <p class="priceTicket">467$ + tx</p>
                </v-col>
                <v-col class="widthPrice">
                  <p class="priceTicket">247$ + tx</p>
                  <p class="priceTicket">327$ + tx</p>
                  <p class="priceTicket">397$ + tx</p>
                </v-col>
              </v-row>
        </v-col>
        <v-col cols="12" md="6" style=""> 
          <h2 class="SectionTitle">Exposants</h2>
          <v-row class="mt-10" style="padding: 0px 5%;">
            <v-col>
              <p class="titleExhibitors">Obtenir un kiosque (LIMITÉ À 20 KIOSQUES)</p>
              <p class="mt-5 f-fifth bold">Coût : 847 $ + tx (Prévente jusqu‘au 15 août)</p>
              <p class="f-fifth bold">Coût régulier : 997 $ + tx</p>
              <p class="f-fifth bold">Ajoutez le cocktail - réseautage : 80 $ + tx (maximum de 2 entrées)</p>
              <p class="bold mt-5">Espace de kiosque de 8’ x 10’ avec option d’aménagement.</p>
              <p class="bold">Au choix :</p>
              <v-row class="mt-2">
                <v-col style="">
                  <p class="optionExhibitorsTitle">OPTION A</p>
                  <p class="optionExhibitorsText">Table rectangulaire et 2 chaises</p>
                </v-col>
                <v-col>
                  <p class="optionExhibitorsTitle">OPTION B</p>
                  <p class="optionExhibitorsText">Table bistro</p>
                </v-col>
                <v-col >
                  <p class="optionExhibitorsTitle">OPTION C</p>
                  <p class="optionExhibitorsText">Aucun mobilier</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <ul class="exponent" style="padding: 0px 5%;">
                    <li><p><span class="bold">Représentation :</span> 2 personnes représentant le partenaire au kiosque qui ont droit aux pauses</p></li>
                    <li><p><span class="bold">Accès aux conférences </span>le jour de l’évènement</p></li>
                    <li><p><span class="bold">Logo </span>dans la section Exposants du site Web de l’évènement</p></li>
                    <li><p><span class="bold">Mention dans les communications </span>de l’évènement sur les réseaux sociaux</p></li>
                  </ul>
                </v-col>
              </v-row>
              <p></p>
            </v-col>
          </v-row>
        </v-col>
        <a class="ShopButton mb-5" href="https://www.eventbrite.ca/e/congres-international-de-lentrepreneuriat-feminin-tickets-916424629737#:~:text=Le%20Congr%C3%A8s%20international%20de%20l,r%C3%A9fl%C3%A9chir%20tout%20l'%C3%A9cosyst%C3%A8me%20entrepreneurial." target="_Blank">
          <button>
            <h2>Accéder à la billetterie</h2>
            <p style="color: white; line-height: 30px; font-size: 14px; text-align: center;">Prévente avec tarif préférentiel</p>
          </button>
        </a>
      </v-row>

    </section>
    <section id="inspiringFounders" class="mt-10">
    <v-row><v-col><h2 class="f-primary SectionTitle">Les femmes en arrière du congrès</h2></v-col></v-row>
      <v-row>
        <v-col class="containerFounder">
          <v-col style="height: 150px; width: 150px; position: absolute; border-radius: 50%; background-color: black; top:-25px; right: -25px; overflow: hidden; border: 5px solid rgb(var(--primary));">
            <img style=" position: relative; z-index: 10; height: inherit; width: inherit; top: 50%; left: 50%; transform: translate(-50%,-50%); object-fit: cover; object-position: center;" src="../../public/Resources/images/congres/CindyBadier.jpg" title="Cindy Badier image" alt="Cindy Badier image" />
          </v-col>
          <v-row><v-col cols="12"><h2>Cindy Badier</h2></v-col></v-row>
          <v-row><v-col cols="12"><h3>Fondatrice du congrès</h3></v-col></v-row>
          <v-row>
            <v-col cols="12"><p class="bold">Cindy excelle en tant que stratège en développement du potentiel, formatrice en entreprise, et conférencière.</p></v-col>
            <v-col cols="12"><p>Dotée d'un esprit à haut potentiel, elle a transformé sa <span class="bold">neurodivergence</span> en un puissant atout en capitalisant sur ses <span class="bold">aptitudes analytiques et sa perspective novatrice.</span></p></v-col>
            <v-col cols="12"><p>Son expertise couvre un éventail de domaines, incluant la Programmation Neuro-Linguistique (PNL), les principes d'intelligence émotionnelle, <span class="bold">le bien-être mental et l'innovation sociale.</span> Elle est aussi particulièrement reconnue pour son efficacité dans le <span class="bold">développement des affaires.</span></p></v-col>
            <v-col cols="12"><p>Cindy est également créatrice de la méthode <span class="italic">Neuroblocs</span>, une technique avant-gardiste inspirée de l'initiative Lego <span class="italic">Serious</span> Play, conçue pour <span class="bold">stimuler la créativité et améliorer la résolution de problèmes.</span></p></v-col>
            <v-col cols="12"><p>En tant que Présidente fondatrice de l'<span class="italic">École alternative de l'entrepreneuriat féminin</span>, Cindy a relevé le défi audacieux de <span class="bold">repenser le développement d'entreprise</span> sous une approche multidimensionnelle.</p></v-col>
            <v-col cols="12"><p>Avec une passion débordante pour l'entrepreneuriat et un <span class="bold">engagement profond</span> envers l'émancipation des femmes, Cindy est également très impliquée dans le <span class="italic">Réseau des femmes d'affaires du Québec.</span></p></v-col>
            <v-col cols="12"><p>Passionnée par l’innovation, <span class="bold">son implication et son authenticité</span> font d'elle une figure incontournable dans le monde de l'entrepreneuriat féminin.</p></v-col>
          </v-row>
        </v-col>
        <v-col class="containerFounder">
          <v-col style="height: 150px; width: 150px; position: absolute; border-radius: 50%; background-color: black; top:-25px; right: -25px; overflow: hidden; border: 5px solid rgb(var(--primary));">
            <img style=" position: relative; z-index: 10; height: inherit; width: inherit; top: 50%; left: 50%; transform: translate(-50%,-50%); object-fit: cover; object-position: center;" src="../../public/Resources/images/congres/ZahyaIdrissi.jpg" title="Cindy Badier image" alt="Cindy Badier image" />
          </v-col>
          <v-row><v-col cols="12"><h2>Zahya Idrissi</h2></v-col></v-row>
          <v-row><v-col cols="12"><h3>Cofondatrice du congrès</h3></v-col></v-row>
          <v-row>
            <v-col cols="12"><p class="bold">Zahya est une coach professionnelle certifiée en PNL (Programmation neuro-Linguistique), mais aussi une chargée de cours à l’Université de Sherbrooke et une future Docteure en administration des affaires (DBA).</p></v-col>
            <v-col cols="12"><p>Elle est également affiliée à la chaire de recherche Mutations et agilités de l’IMS Geneva où elle collabore avec des experts de tous horizons.</p></v-col>
            <v-col cols="12"><p>Son expertise est axé sur la gestion dans son ensemble (de projet, d’innovation, de développement local et international et stratégique).</p></v-col>
            <v-col cols="12"><p>Au-delà de ses accomplissements professionnels, Zahya est reconnue pour son <span class="bold">esprit d'innovation et son dynamisme.</span> Sa créativité et son approche humaine du coaching font d'elle une véritable source d'inspiration pour ses pairs et ses étudiants.</p></v-col>
            <v-col cols="12"><p><span class="bold">Passionnée par le développement du potentiel humain,</span> elle apporte une touche de chaleur et d'authenticité à chaque interaction, faisant d'elle une co-fondatrice inestimable pour ce congrès.</p></v-col>
            <v-col cols="12"><p>Zahya combine avec finesse <span class="bold">expertise</span> et <span class="bold">sensibilité humaine</span> dans sa pratique. Sa capacité à établir des ponts entre la théorie et la pratique fait d'elle une <span class="bold">alliée précieuse pour les femmes entrepreneures.</span></p></v-col>
            <v-col cols="12"><p><span class="bold">Son esprit de collaboration et d'ouverture</span> permet un environnement où l'innovation et la diversité des idées sont célébrées.</p></v-col>
          </v-row>
        </v-col>
        <!--
        <v-col class="containerFounder">
          <v-col style="height: 150px; width: 150px; position: absolute; border-radius: 50%; background-color: black; top:-25px; right: -25px; overflow: hidden; border: 5px solid rgb(var(--primary));">
            <img style=" position: relative; z-index: 10; height: inherit; width: inherit; top: 50%; left: 50%; transform: translate(-50%,-50%); object-fit: cover; object-position: center;" src="../../public/Resources/images/congres/catherineRobillard.jpg" title="Cindy Badier image" alt="Cindy Badier image" />
          </v-col>
          <v-row><v-col cols="12"><h2>Catherine Robillard</h2></v-col></v-row>
          <v-row>
            <v-col cols="12"><p class="italic f-primary">Passionnée d'entrepreneuriat et autrice du Guide pratique pour entrepreneurs ''Le BACKSTAGE de l'entrepreneuriat''.</p></v-col>
            <v-col cols="12"><p>Catherine veut s'assurer que les entrepreneur-es <span class="bold">concrétisent leurs ambitions de façon saine et pérenne.</span> Outiller et soutenir le dirigeant d'entreprise afin qu'il ou elle puisse être en mesure <span class="bold">d'accomplir sa propre mission,</span> contribuer à plus grand qu'elle-lui et ainsi, <span class="bold">impacter</span> l'évolution de son industrie, voire de la société. Sa vision est qu'enfin, ces entrepreneur-es soient reconnu-es comme les <span class="bold">acteur-ices de changement de la société.</span></p></v-col>
            <v-col cols="12"><p>Catherine est non seulement <span class="bold">une coach et mentor incontournable</span> dans le monde des affaires, mais elle est aussi un atout de grande valeur dans l'équipe.</p></v-col>
            <v-col cols="12"><p>Son dynamisme, son sens de l'organisation et son goût pour l'animation apportent une richesse supplémentaire au congrès.</p></v-col>
          </v-row>
        </v-col>
        <v-col class="containerFounder">
          <v-col style="height: 150px; width: 150px; position: absolute; border-radius: 50%; background-color: black; top:-25px; right: -25px; overflow: hidden; border: 5px solid rgb(var(--primary));">
            <img style=" position: relative; z-index: 10; height: inherit; width: inherit; top: 50%; left: 50%; transform: translate(-50%,-50%); object-fit: cover; object-position: center;" src="../../public/Resources/images/congres/Carol-AnnOuimet.jpg" title="Cindy Badier image" alt="Cindy Badier image" />
          </v-col>
          <v-row><v-col cols="12"><h2>Carol-Ann Ouimet</h2></v-col></v-row>
          <v-row>
            <v-col cols="12"><p class="italic f-primary">Virtuose de l’optimisation d’entreprise</p></v-col>
            <v-col cols="12"><p>Carol-Ann aide les femmes entrepreneures à <span class="bold">optimiser leur temps</span> en établissant des processus clairs, précis et plaisants. </p></v-col>
            <v-col cols="12"><p>Elle les accompagne aussi en créant des outils, des modèles et en effectuant des tâches administratives pour les <span class="bold">aider à développer efficacement leur entreprise.</span></p></v-col>
            <v-col cols="12"><p>Carol-Ann est une multipassionnée qui adore l’entrepreneuriat. </p></v-col>
            <v-col cols="12"><p>L’implication dans ce congrès est importante pour elle, car cet événement regroupe des valeurs qui lui sont propres.</p></v-col>
            <v-col cols="12"><p>Sa perspicacité, sa proactivité et sa pétillance en font une <span class="bold">aide précieuse et efficace.</span></p></v-col>
            <v-col cols="12"><p>Carol-Ann est définitivement le support 3.0 dont toutes les entrepreneures ont besoin.</p></v-col>
          </v-row>
        </v-col>-->
      </v-row>
    </section>
    <section id="picture" class="grid-pad"  style="position: relative; z-index: 100;">
      <v-row><v-col><h2 class="txt-c SectionTitle">Photos</h2></v-col></v-row>
      <v-row>
        <v-carousel cycle :show-arrows="false" hide-delimiters infinite style="height: 100%; padding-top: 20px; ">
          <template  v-for="(item, index) in pictureEvent">
            <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index">
              <v-row class="flex-nowrap" style="height:84%">
                <template v-for="(n,i) in columns">
                  <template v-if="(+index + i) < pictureEvent.length">
                    <v-col :key="i">
                      <v-sheet v-if="(+index + i) < pictureEvent.length" height="100%" class="pt-8" style="background-color: transparent;">
                        <v-row class="photoContainer" style="position: relative;" justify="center">
                          <v-col class="" @click="setImage(pictureEvent[index + i])" @mouseover="changeEventPictureHover(1,index,i)" @mouseleave="changeEventPictureHover(2,index,i)">
                            <v-row :id="'eventHover' + [index + i]" class="pictureHover" >
                              <v-col class="d-flex align-center justify-center">
                                <h2 style="font-size: 2.5em; color: rgb(var(--forth)); text-shadow: 1px 1px 3px black !important;" >{{pictureEvent[index + i].name}}</h2>
                              </v-col>
                            </v-row>
                            <div class="boxInnerShadow"></div>                               
                            <img v-if="pictureEvent[index + i].type == 'portrait'" :src="pictureEvent[index + i].img" :truesrc="pictureEvent[index + i].img" :title="pictureEvent[index + i].name" :alt="'voici notre évènement pictureEvent[index + i].name'" style="height:100%; width:auto; position: absolute; display: block; left: 50%; top: 50%; transform: translate(-50%,-50%);"/>
                            <img v-else :src="pictureEvent[index + i].img" :truesrc="pictureEvent[index + i].img" :title="pictureEvent[index + i].name" :alt="'voici notre évènement pictureEvent[index + i].name'" style="height:auto; width:100%; position: absolute; display: block; left: 50%; top: 50%; transform: translate(-50%,-50%);"/>
                          </v-col>
                        </v-row>
                      </v-sheet>
                    </v-col>
                  </template>
                </template>
              </v-row>
            </v-carousel-item>
          </template>
        </v-carousel>
      </v-row>

    </section>
  </div>
</template>
<style scoped>
    h3 { font-size: clamp(1.5rem, 8vw, 2rem);  }
    p { font-size: clamp(1rem, 2.4vw, 1.25rem);  }
    @-moz-document url-prefix() {
      ul li { font-size: clamp(1rem, 2.4vw, 1.5rem); line-height: 105% !important; }
    }
    ul li { font-size: clamp(1rem, 2.4vw, 1.5rem); line-height: normal; }
    .max100 {max-width: 100%;}
    .videoContainer { height: 400px; width: 100%; background-color: grey; }
    .sectionMargin { margin: 50px 0px; }
    .whyContainer { position: absolute; min-width: 350px; width: 350px; height: 400px; margin: auto; border-radius: 45px; background-color: rgb(var(--primary)); overflow: hidden; backface-visibility: hidden; transition: transform 0.6s; }
    .card { min-width: 350px; width: 350px; height: 400px; position: relative; margin: auto;}
    .whyContainer .portrait,.whyContainer .landscape { height: inherit; width: inherit; }
    .whyContainer .portrait img { position: relative; z-index: 10; height: auto; width: inherit; top: 50%; left: 50%; transform: translate(-50%,-50%); }
    .whyContainer .landscape img { position: relative; z-index: 10; height: inherit; width: auto; top: 50%; left: 50%; transform: translate(-50%,-50%); }
    .whyButton { cursor: unset; position: absolute; z-index: 100; top: 50%; left: 50%; transform: translate(-50%,-50%); background-color: rgb(var(--fifth)) !important; padding: 5px 40px; border-radius: 10px; overflow: hidden;  }
    .whyButton h2 { color: white !important; }
    .congressInternationBG { width: 75%; height: auto; background-color: rgb(var(--fifth)); margin: auto; border-radius: 50px; padding: 50px; color: white;  }
    .widthDot {max-width: 175px;}
    button { padding: 10px 60px; }
    button:hover { background-color: rgb(var(--fifth)) !important; }
    button:hover h2 { color: white !important;}
    #infoCongres { margin: 50px 0px; }
    #infoCongres p { font-family: roboto, sans-serif; font-weight: 100; text-align: justify; padding: 10px 0px; }
    #infoCongres ul li { font-family: roboto, sans-serif; font-weight: 100; text-align: justify; padding: 5px 0px;  }
    .exponent li::marker { color: black; }
    li::marker { color: rgb(var(--primary)); }
    #PricingCongres p { font-family: roboto, sans-serif; font-size: clamp(0.6rem, 1.4vw, 1rem) !important; text-align: left;  }
    .ticketTitle { text-align: center !important; font-weight: 700; text-transform: uppercase; }
    .includeTitle { visibility: hidden; text-align: center !important;   }
    .dot { width: 15px; height: 15px; border-radius: 50% !important;  background-color: rgb(var(--primary)); margin: 0px auto 10px auto; display: block; }
    .noneIncludeTitle { text-align: center !important; color: rgb(var(--primary)); font-weight: 700; line-height: 135%; }
    .borderRight { border-right: 1px solid black; }
    .borderTicketRight { border-right: 1px solid black; }
    .ticketChoice {line-height: 400%;}
    .widthPrice { max-width: 100px; }
    .ticketTimeTitle { font-weight: 700; text-transform: uppercase; }
    .ticketTimeTitleName { font-weight: 100; font-size: 0.9em; text-transform: lowercase;   }
    .priceTicket { text-align: center !important; font-weight: 700;}
    .titleExhibitors { font-weight: 700; font-size: 1.5em !important; }
    .optionExhibitorsTitle {  text-align: center !important; color: rgb(var(--fifth)); font-weight: 700; }
    .optionExhibitorsText {  text-align: center !important; font-weight: 700; }
    #inspiringFounders { padding: 0px 5%; margin-bottom: 50px; }
    #inspiringFounders p { font-family: roboto;  font-weight: 100; text-align: justify; }
    #inspiringFounders h2 { text-align: left; color: rgb(var(--fifth)); font-size: 72px; }
    #inspiringFounders h3 { text-align: left; color: rgb(var(--primary)); font-size: 32px; }
    .containerFounder { min-width: 600px; position: relative; padding: 25px; margin: 15px 25px; border: 5px solid rgb(var(--primary)); border-radius: 45px; text-align: justify;  }
    .ShopButton { cursor: pointer; display: block; margin: auto;  border-radius: 10px; overflow: hidden;  }
    /*#region photo*/
    .photoContainer { width: 400px; height: 500px; background-color: rgb(var(--primary)); border-radius: 45px; margin: auto; overflow: hidden; }
    .pictureHover { position: absolute; z-index: 1000; background-color: rgba(var(--fifth),0.6); height: 100%; width: 100%; visibility: hidden; }
    /*#endregion */
    @media handheld, only screen and (max-width: 600px) {
      .textMobile { line-height: 50% !important; }
      .marginTopMobile {margin-top: 10px;}
      .congressInternationBG { width: 95%; padding: 25px; color: white;  }
      .whyContainer { margin: 5px; }
      .widthDot {max-width: 125px;}
      .dot { margin: 0px auto 8px auto; }
      .ticketTitle { font-size: 0.50rem; }
      .photoContainer { width: 100%; height: 400px; }
      .ticketChoice {line-height: normal;}
      .widthPrice { max-width: 70px; }
      _::-webkit-full-page-media, _:future, :root .safari_only { margin-top: 10px;}
      _::-webkit-full-page-media, _:future, :root .safari_only_dot { margin-top: 12px;}
    }
    @media handheld, only screen and (max-width: 960px) {
      .card { margin-top: 20px; }
      #inspiringFounders h2 { text-align: left; color: rgb(var(--fifth)); font-size: 24px; }
      #inspiringFounders h3 { text-align: left; color: rgb(var(--primary)); font-size: 18px; }
      .containerFounder {  min-width: 100%; margin: 15px 0px;}
      .dot { width: 10px; height: 10px; } 
    }
    @media handheld, only screen and (max-width: 1279px) {}
    @media handheld, only screen and (max-width: 1378px) {}
</style>
<script>

export default {
  name: 'Congress',
  data:()=>({
    showSelectedImg: false,
    dialogType: 0,
    imgSelected: null,
    columns: 3,
    pictureEvent:[
      {name:'Congrès pour les enjeux de l’entrepreneuriat féminin', img:'/Resources/images/congres/photo1.jpg',type:'portrait'},
      {name:'Congrès pour les enjeux de l’entrepreneuriat féminin', img:'/Resources/images/congres/photo2.jpg',type:'portrait'},
      {name:'Congrès pour les enjeux de l’entrepreneuriat féminin', img:'/Resources/images/congres/photo3.jpg',type:'portrait'},
      {name:'Congrès pour les enjeux de l’entrepreneuriat féminin', img:'/Resources/images/congres/photo4.jpg',type:'portrait'},
      {name:'Congrès pour les enjeux de l’entrepreneuriat féminin', img:'/Resources/images/congres/photo5.jpg',type:'portrait'},
      {name:'hôtel Plaza Le Rizz Montréal', img:'/Resources/images/congres/photo6.jpg',type:'paysage'},
      {name:'hôtel Plaza Le Rizz Montréal', img:'/Resources/images/congres/photo7.jpg',type:'paysage'},
      {name:'hôtel Plaza Le Rizz Montréal', img:'/Resources/images/congres/photo8.jpg',type:'portrait'},
      {name:'hôtel Plaza Le Rizz Montréal', img:'/Resources/images/congres/photo9.jpg',type:'portrait'}
    ],
  }),
  created() { window.addEventListener("resize", this.changeColumns); },
  destroyed() { window.removeEventListener("resize", this.changeColumns); },
  mounted() { this.changeColumns(); },
  methods:{
    changeColumns() {
      let width = window.innerWidth;
      if (width > 1800) { this.columns = 4; }
      else if (width > 1200) { this.columns = 3; }
      else if (width > 800) { this.columns = 2; } 
      else if (width < 800) { this.columns = 1; } 
    },
    changeEventPictureHover: function(change,index,i) {
      let num = index + i;
      let id = 'eventHover' + num;
      let hoverPicture = document.getElementById(id);
      if(change == 1){ hoverPicture.style.visibility = 'visible';} 
      else{ hoverPicture.style.visibility = 'hidden'; }

    },
    setImage(item, type = 1){
      this.dialogType = type;
      this.imgSelected = item;
      this.showSelectedImg = true;
    }
  },
  components: { }
}
</script>
