<template>
    <div>
        <div>
            <div class="header">
                <v-row class=" " no-gutters>
                    <v-col class="colLogo"><router-link style="width: 100%;" to="/"><img class="logoCompagny" src="/Resources/images/congres/Logo_CIEF.png"  title="logo Congrès international de l'entrepreneuriat féminin" alt="visuel de l'entreprise Congrès international de l'entrepreneuriat féminin" /></router-link></v-col>
                    <v-col class="d-flex align-end  justify-end  menu" >
                        <!--
                        <router-link to="/"><h2>Accueil</h2></router-link>
                        <router-link to="/Services"><h2>Services</h2></router-link>
                        <router-link to="/Contact"><h2>Contact</h2></router-link>   
                        <router-link to="/Congres"><h2>Congrès</h2></router-link>    
                        --> 
                        <a href="mailto:contact.congresief@gmail.com" target="_blank"><h2>Contact</h2></a>                 
                    </v-col>
                    <v-col class="socialMedia">
                        <a href="https://www.linkedin.com/in/cindy-badier/" target="_blank">
                            <div class="logoLinkedin"></div>
                        </a>
                        <a href="https://www.instagram.com/cindy_badier/" target="_blank">
                            <div class="logoInstagram"></div>
                        </a>
                        <a href="https://www.facebook.com/EEF.CindyBadier" target="_blank">
                            <div class="logoFacebook"></div>
                        </a>  
                    </v-col>
                </v-row>
            </div>
            
        </div>
    </div>
  </template>
  <style scoped>
        .v-row { height: 100%; }
        .v-col { height: inherit;}
        .header { position: fixed; top:0; z-index: 1000; padding: 5px 25px; height: 80px; width: 100%; background-color: white; box-shadow: 1px 0px 10px rgb(var(--primary)); overflow: hidden; }
        .colLogo { max-width: 200px;}
        .logoCompagny { height: 100%; width: auto; }
        .menu a h2 { padding: 0px 5px; color: rgb(var(--secondary)); font-family: argentReg serif !important; font-size: 1.25em; }
        .menu a h2:hover { color: rgb(var(--primary)); text-decoration: underline; }
        .socialMedia { position: absolute; top:10px; right: 30px; height: 30%; max-width: 50%; }
        .socialMedia a  { height: 25px; width: auto; float: right; padding: 0px 2px; }
        .logoFacebook { height: 25px; width: 25px; background-image: url('../../public/Resources/images/general/facebook.svg');  }
        .logoFacebook:hover { background-image: url('../../public/Resources/images/general/facebookOnHover.svg'); }
        .logoInstagram { height: 25px; width: 25px; background-image: url('../../public/Resources/images/general/instagram.svg');  }
        .logoInstagram:hover { background-image: url('../../public/Resources/images/general/instagramOnHover.svg'); }
        .logoLinkedin { height: 25px; width: 25px; background-image: url('../../public/Resources/images/general/linkedin.svg');  }
        .logoLinkedin:hover { background-image: url('../../public/Resources/images/general/linkedinOnHover.svg'); }
        @media handheld, only screen and (max-width: 600px) {
            .menu a h2 { font-size: 1em; }
            .menu { position: relative; width: 100%; }
            .logoFacebook { height: 15px; width: 15px; background-image: url('../../public/Resources/images/general/facebook.svg');  }
            .logoInstagram { height: 15px; width: 15px; background-image: url('../../public/Resources/images/general/instagram.svg');  }
            .logoLinkedin { height: 15px; width: 15px; background-image: url('../../public/Resources/images/general/linkedin.svg');  }
        }
        @media handheld, only screen and (max-width: 960px) {}
        @media handheld, only screen and (max-width: 1279px) {}
        @media handheld, only screen and (max-width: 1378px) {}
  </style>
  <script>
  
  export default {
    name: 'Header',
    data:()=>({}),
    mounted() {},
    methods:{},
    components: { }
  }
  </script>
  